import React from 'react';

import Header from '../../components/Header';

const HeaderTemp = () => {
  return (
    <Header />
  );
};

export default HeaderTemp;
